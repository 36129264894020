<template>
  <div
    class="flex items-center text-teal ml-5 mt-3 cursor-pointer w-24"
    @click="reRoute"
  >
    <span class="material-icons-outlined"> arrow_back </span>
    go Back
  </div>
  <teleport to="body">
    <job-person-spec-modal
      @closeModal="toggleJobSpecModal(false)"
      v-if="jobSpecModalOpen"
      :jobSpecification="getHiringRequest.job_specification"
      :personSpecification="getHiringRequest.person_specification"
    />
  </teleport>
  <screen-split-view v-if="!isLoading">
    <template v-slot:leftSide>
      <transition appear>
        <staff-request-form
          :data="getHiringRequest"
          @toggleJobSpecModal="toggleJobSpecModal(true)"
        />
      </transition>
    </template>
    <template v-slot:rightSide>
      <vacancy-applicants-title
        :status="statusToggle"
        :liveStatus="isAddLive"
        @toggle-online-postings="toggleOnlinePostings"
      />
      <transition appear mode="out-in">
        <section v-if="!showOnlinePostings">
          <!-- <table-skeleton v-if="getHiringRequestApplicants && paginating" /> -->
          <vacancy-applicants-table
            v-if="getHiringRequestApplicants"
            @paginate-applicants="updateApplicantsPage"
            :data="getHiringRequestApplicants"
            :paginatePage="applicantsPage"
            :paginatingStatus="paginating"
            @update-profile-card="updateProfileCard"
          />
          <transition appear mode="out-in">
            <mini-profile-card
              v-if="filteredUserProfile"
              :data="filteredUserProfile"
              :cardType="'applicants'"
              class="mx-10 my-2 p-10"
            />
          </transition>
        </section>
        <section v-else>
          <online-postings
            v-if="!jobPostingsIsLoading"
            :jobPostingsData="getJobPostings"
            :liveStatus="isAddLive"
            @update-job-postings="
              fetchJobPostings(this.$route.params.manageVacancy)
            "
          />
        </section>
      </transition>
    </template>
  </screen-split-view>
  <screen-split-skeleton v-else />
</template>
<script>
import screenSplitSkeleton from "@/components/ui/baseComponents/skeletons/screenSplitSkeleton.vue";
import jobPersonSpecModal from "@/components/modal/jobPersonSpecModal.vue";
import staffRequestForm from "@/components/ui/baseComponents/Recruitment/staffRequestForm.vue";
import screenSplitView from "@/components/ui/baseComponents/screenSplitView.vue";
import vacancyApplicantsTable from "@/components/ui/baseComponents/Tables/vacancyApplicantsTable.vue";
import miniProfileCard from "@/components/ui/baseComponents/miniProfileCard.vue";
import onlinePostings from "@/components/ui/baseComponents/onlinePostings.vue";
import vacancyApplicantsTitle from "@/components/ui/baseComponents/vacancyApplicantsTitle.vue";
import moment from "moment";
export default {
  components: {
    staffRequestForm,
    jobPersonSpecModal,
    screenSplitView,
    vacancyApplicantsTable,
    miniProfileCard,
    onlinePostings,
    vacancyApplicantsTitle,
    screenSplitSkeleton,
  },
  async created() {
    this.isLoading = true;
    await this.fetchHiringRequest(this.$route.params.manageVacancy);
    await this.fetchHiringRequestApplicants(this.$route.params.manageVacancy);
    await this.fetchJobPostings(this.$route.params.manageVacancy);
    this.isLoading = false;
    if (this.$route.query.success === "true") {
      this.$toast.success(`Success`);
    } else if (this.$route.query.success === "false") {
      this.$toast.error(`Error`);
    }
  },
  data() {
    return {
      statusToggle: false,
      showOnlinePostings: false,
      jobSpecModalOpen: false,
      isLoading: false,
      jobPostingsIsLoading: false,
      filteredUserProfile: null,
      liveStatus: "",
      applicantsPage: 1,
      paginating: false,
    };
  },
  methods: {
    reRoute() {
      this.$router.back();
    },
    toggleJobSpecModal(value) {
      this.jobSpecModalOpen = value;
    },
    toggleOnlinePostings() {
      this.showOnlinePostings = !this.showOnlinePostings;
      this.statusToggle = !this.statusToggle;
    },
    updateProfileCard(id) {
      this.filterSelectedUser(id);
    },
    filterSelectedUser(passedID) {
      this.filteredUserProfile = this.getHiringRequest.applicants.filter(
        function (user) {
          return user.id == passedID;
        }
      );
    },
    async fetchJobPostings(requestFormId) {
      try {
        this.jobPostingsIsLoading = true;
        await this.$store.dispatch("REnewHire/fetchJobPostings", {
          hiring_request: requestFormId,
        });
        this.jobPostingsIsLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchHiringRequest(requestFormId) {
      try {
        await this.$store.dispatch("HQnewHire/fetchHiringRequest", {
          hiring_request: requestFormId,
        });
        // if (this.getHiringRequest && this.getHiringRequest.status) {
        //   this.payload.status = this.getHiringRequest.status;
        // }
        // if (this.getHiringRequest && this.getHiringRequest.decision_reason) {
        //   this.payload.decision_reason = this.getHiringRequest.decision_reason;
        // }
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    updateApplicantsPage(page) {
      this.applicantsPage = page;
      this.fetchHiringRequestApplicants(this.$route.params.manageVacancy);
    },
    async fetchHiringRequestApplicants(requestFormId) {
      this.paginating = true;
      try {
        await this.$store.dispatch("REnewHire/fetchHiringRequestApplicants", [
          {
            hiring_request: requestFormId,
          },
          this.applicantsPage,
        ]);
        this.paginating = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
  computed: {
    getHiringRequest() {
      return this.$store.getters["HQnewHire/getHiringRequest"];
    },
    // Check if date is AFTER end date
    isAddLive() {
      return this.getJobPostings?.some(
        (date) => moment(date.end_date) >= moment()
      );
    },
    getJobPostings() {
      return this.$store.getters["REnewHire/getJobPostings"];
    },
    getHiringRequestApplicants() {
      return this.$store.getters["REnewHire/getHiringRequestApplicants"];
    },
  },
};
</script>
