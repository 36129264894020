<template>
  <div>
    <section
      class="grid grid-cols-3 gap-1 justify-items-stretch place-items-center bg-white rounded-xl m-10 my-5 p-5 gap-x-2"
    >
      <label class="" for="siteName">Site Name:</label>
      <v-select
        :disabled="disabledStatus"
        v-model="site_name"
        name="siteName"
        class="style-chooser-site-name col-span-2"
        placeholder="Select Platform"
        label="customLabel"
        :options="dropdownData"
      ></v-select>
      <label for="linkToPost" class="flex items-center"
        ><span class="material-icons"> link </span>Link to post:
      </label>
      <input
        :disabled="disabledStatus"
        class="border border-grey rounded-md p-1 bg-lightGrey col-span-2"
        type="text"
        placeholder="Link to post"
        v-model="link"
      />
      <label for="postedOn">Posted on:</label>
      <input
        :disabled="disabledStatus"
        class="border border-grey rounded-md p-1 bg-lightGrey col-span-2"
        type="text"
        placeholder="DD/MM/YYYY"
        v-model="post_date"
      />
      <label for="endsOn">Ends on:</label>
      <input
        :disabled="disabledStatus"
        class="border border-grey rounded-md p-1 bg-lightGrey col-span-2"
        type="text"
        placeholder="DD/MM/YYYY"
        v-model="end_date"
      />
    </section>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    disabledStatus: {
      type: Boolean,
    },
  },
  mounted() {
    this.id = this.data.id;
    this.hiring_request = this.data.hiring_request_id;
    this.site_name = this.data.site_name;
    this.post_date = this.data.post_date;
    this.end_date = this.data.end_date;
    this.link = this.data.link;
  },
  data() {
    return {
      id: null,
      hiring_request: null,
      site_name: null,
      post_date: null,
      end_date: null,
      link: null,
      dropdownValue: this.data.site_name,
      dropdownData: [
        {
          customLabel: "Indeed",
          value: "Indeed",
        },
        {
          customLabel: "Reed",
          value: "Reed",
        },
        {
          customLabel: "Totaljobs",
          value: "Totaljobs",
        },
        {
          customLabel: "CWJobs",
          value: "CWJobs",
        },
      ],
    };
  },
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.style-chooser-site-name .vs__dropdown-toggle,
.style-chooser-site-name .vs__dropdown-menu {
  border: 1px solid #425563;
  height: 10rem;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}
.style-chooser-site-name .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}
.style-chooser-site-name-error .vs__clear,
.style-chooser-site-name .vs__open-indicator {
  fill: black;
}
</style>
