<template>
  <button
    v-if="mode === 'confirm' || !mode"
    class="shadow-md border font-bold py-1 px-16 my-1 rounded-full flex-1 cursor-default"
    :class="backgroundColour"
  >
    <slot></slot>
  </button>

  <button
    v-else-if="mode === 'cancel'"
    class="bg-red hover:bg-darkRed text-white font-bold py-2 px-16 my-3 rounded-full mx-2"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
    backgroundColour: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
