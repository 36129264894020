<template>
  <div class="flex self-start w-full space-x-7 h-full overflow-hidden">
    <div
      class="flex flex-col gap-y-4 rounded-xl bg-lightGrey text-grey w-1/2 my-2 ml-2 overflow-scroll overflow-x-hidden"
    >
      <horizontal-card-skeleton class="mt-4" />
      <table-skeleton />
      <horizontal-card-skeleton />
      <table-skeleton />
      <horizontal-card-skeleton class="mb-2" />
    </div>

    <div
      class="flex flex-col gap-y-4 rounded-xl bg-lightGrey text-grey w-1/2 my-2 ml-2 overflow-scroll overflow-x-hidden"
    >
      <table-skeleton class="mt-4" />
      <horizontal-card-skeleton />
      <table-skeleton />
      <horizontal-card-skeleton />
      <horizontal-card-skeleton class="mb-2" />
    </div>
  </div>
</template>

<script>
import horizontalCardSkeleton from "./horizontalCardSkeleton.vue";
import tableSkeleton from "./tableSkeleton.vue";
export default {
  components: {
    horizontalCardSkeleton,
    tableSkeleton,
  },
};
</script>
