<template>
  <section class="bg-white rounded-xl mx-10">
    <div class="flex justify-between mx-5 py-3 items-center">
      <p class="text-lg">{{ jobPostingsData.length }} vacancie(s) posted yet.</p>
      <span
        @click="addVacancyCard"
        class="text-white cursor-pointer hover:scale-110 transform-gpu duration-200"
      >
      <small-button class="bg-teal cursor-pointer w:9/10"
        >Post Vacancy</small-button
      >
      </span>
    </div>
  </section>
  <template v-if="!isLoading">
    <template v-for="item in jobPostingsData" :key="item.id">
      <vacancy-listing :disabledStatus="true" :data="item" />
    </template>
  </template>
  <transition>
    <template v-if="createNew">
      <new-vacancy-listing @create-job-posting="addVacancy" />
    </template>
  </transition>
</template>

<script>
import smallButton from "@/components/ui/baseComponents/smallButton.vue";
import vacancyListing from "@/components/ui/baseComponents/vacancyListing.vue";
import newVacancyListing from "@/components/ui/baseComponents/newVacancyListing.vue";
export default {
  emits: ["update-job-postings"],
  components: {
    smallButton,
    vacancyListing,
    newVacancyListing,
  },
  async mounted() {},
  props: {
    jobPostingsData: {
      type: Object,
      required: true,
    },
    liveStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      createNew: false,
    };
  },
  methods: {
    async addVacancyCard() {
      this.createNew = !this.createNew;
      // Makes sure that the component has been rendered before trying to scroll to it
      await this.$nextTick();
      if (this.createNew) {
        document.getElementById("newVacancyListing").scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    addVacancy() {
      this.createNew = !this.createNew;
      this.$emit("update-job-postings");
    },
  },
};
</script>
