<template>
  <div class="flex self-start w-full space-x-7 h-full overflow-hidden">
    <div
      class="rounded-xl bg-lightGrey text-grey w-1/2 my-2 ml-2 overflow-scroll overflow-x-hidden"
    >
      <slot name="leftSide" />
    </div>

    <div
      class="rounded-xl bg-lightGrey text-grey w-1/2 my-2 ml-2 overflow-scroll overflow-x-hidden"
    >
      <slot name="rightSide" />
    </div>
  </div>
</template>
