<template>
  <div
    class="
      bg-white
      rounded-xl
      shadow-md
      m-auto
      mx-10
      flex flex-col
      justify-center
      items-center
    "
  >
    <table class="m-auto text-grey my-5 w-11/12">
      <thead class="text-teal">
        <tr>
          <th class="py-2 text-left">EMPLOYEE</th>
          <th class="py-2">APPLICATION DATE</th>
          <th class="py-2">APPLICATION STATUS</th>
          <th class="py-2">ACTION</th>
        </tr>
      </thead>

      <tbody v-if="!paginatingStatus" class="text-center">
        <tr v-for="(user, index) in data.data" :key="index">
          <td class="border-b text-left">
            <p
              @click="$emit('update-profile-card', user.id)"
            >
              {{ `${user.profile.first_name} ${user.profile.last_name}` }}
            </p>
          </td>
          <td class="border-b">
            {{ convertedTime(data.created_at) }}
          </td>
          <td v-if="user.offer" class="border-b">
            <p>{{ checkOffer(user.offer.status) }}</p>
          </td>
          <td v-else class="border-b"><p>No Updates</p></td>
          <td class="border-b cursor-pointer">
            <tableOptionsDropdown
              :show="showDropdown"
              @click="toggleDropdown(user)"
              :id="user.id"
              :options="dropdownOptions"
              :dynamicParam="user?.user_id"
            >
              <span class="material-icons"> more_horiz </span>
            </tableOptionsDropdown>
          </td>
        </tr>
      </tbody>
      <tbody v-if="data?.data.length < 1">
        Record not found.
      </tbody>
    </table>
    <table-skeleton class="mb-10" v-if="paginatingStatus" />
    <my-pagination
      :options="options"
      v-model="page"
      :records="data.total"
      :per-page="data.per_page"
      @paginate="myCallback"
    >
    </my-pagination>
  </div>
</template>

<script>
import moment from "moment";
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import tableSkeleton from "@/components/ui/baseComponents/skeletons/tableSkeleton.vue";
import tableOptionsDropdown from "@/components/ui/baseComponents/tableOptionsDropdown.vue";
export default {
  components: { tableSkeleton, tableOptionsDropdown },
  props: {
    data: {
      type: Object,
      required: true,
    },
    link_1: {
      type: String,
    },
    myParameter_1: {
      type: String,
    },
    paginatePage: {
      type: Number,
      required: true,
    },
    paginatingStatus: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.page = this.paginatePage;
  },
  data() {
    return {
      page: 1,
      options: {
        template: markRaw(customPaginate),
      },
      showDropdown: 0,
      dropdownOptions: [
        {
          type: "link",
          linkName: "re-manage-vacancy-view-user",
          paramName: "userId",
          label: "View User Information",
        },
        {
          type: "link",
          linkName: "re-create-new-interview-with-user",
          paramName: "id",
          label: "Schedule Interview",
        },
        {
          type: "link",
          linkName: "re-manage-vacancy-view-user",
          paramName: "userId",
          label: "Reject Candidate",
        },
        {
          type: "link",
          linkName: "re-manage-vacancy-view-user",
          paramName: "userId",
          label: "Review Interview Document",
        },
        {
          type: "link",
          linkName: "re-manage-vacancy-new-offer",
          paramName: "userId",
          label: "New offer",
        },
        {
          type: "link",
          linkName: "re-manage-vacancy-view-user",
          paramName: "userId",
          label: "Link Applicant to New Role",
        },
      ],
    };
  },
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    myCallback(e) {
      this.$emit("paginate-applicants", e);
    },
    toggleDropdown(el) {
      if (this.showDropdown === el.id) {
        this.showDropdown = 0;
      } else {
        this.showDropdown = el.id;
      }
    },
    checkOffer(data) {
      switch (data) {
        case "made":
          return "Offer Made";
        case "declined":
          return "Offer Declined";
      }
    },
  },
  computed: {
    // modifyOption(id) {
    //   return (this.dropdownOptions.paramValue = id);
    // },
  },
};
</script>
