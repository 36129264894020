<template>
  <div
    v-if="data != null"
    class="
      flex flex-col
      gap-y-2
      lg:flex-row
      justify-between
      lg:items-center
      bg-white
      rounded-xl
    "
  >
    <section class="px-2 mx-2 flex flex-col items-center">
      <img
        alt="Profile"
        src="@/assets/1.png"
        class="mx-auto object-cover rounded-full w-20 h-20 sm:w-24 sm:h-24"
      />
      <p class="cursor-pointer">Profile</p>
    </section>
    <section class="lg:border-l lg:border-r px-2 mr-2">
      <p class="font-bold">
        {{ data[0]?.profile?.first_name }} {{ data[0]?.profile?.last_name }}
      </p>
      <p>Phone:</p>
      <p class="text-darkGrey">H: {{ data[0]?.profile?.home_phone }}</p>
      <p class="text-darkGrey">T: {{ data[0]?.profile?.mobile_phone }}</p>
      <p class="text-darkGrey">W: {{ data[0]?.profile?.work_phone }}</p>
      <p>Email:</p>
      <p class="text-darkGrey">
        <template v-if="cardType === 'search'">
          W: {{ data[0]?.email }}
        </template>
        <template v-if="cardType === 'applicants'">
          W: {{ data[0]?.profile?.user?.email }}
        </template>
      </p>

      <p class="text-darkGrey">P: {{ data[0]?.profile?.professional_email }}</p>
      <p>NSH Smart Card:</p>
      <p class="text-darkGrey">
        {{ data[0]?.profile?.nhs_card }} - {{ data[0]?.profile?.nhs_number }}
      </p>
    </section>
    <section class="pt-3 px-2 mr-2">
      <!-- <p class="font-bold">Application Information</p>
      <p>Application Date:</p>
      <p class="text-darkGrey">
        <template v-if="cardType === 'search'">
          {{ `${convertDate(data[0]?.created_at)}` }}
        </template>
        <template v-if="cardType === 'applicants'">
          {{ `${convertDate(data[0]?.profile?.user?.created_at)}` }}
        </template>
      </p>

      <p>Interview 1 Date:</p>
      <p v-if="data[0]?.interview_schedules[0]" class="text-darkGrey">
        {{
          new Date(data[0]?.interview_schedules[0]?.date).toLocaleDateString(
            "en-GB"
          )
        }}
      </p>
      <p v-else>Unavailable</p> -->
      <!-- <p>Interview 1 Score:</p>
      <p v-if="data[0]?.interview_schedules[0]" class="text-darkGrey">
        {{ data[0]?.interview_schedules[0]?.interview_score?.total }}/80
      </p>
      <p v-else>Unavailable</p>
      <p>Interview 2 Date:</p>
      <p v-if="data[0]?.interview_schedules[1]" class="text-darkGrey">
        {{
          new Date(data[0]?.interview_schedules[1]?.date).toLocaleDateString(
            "en-GB"
          )
        }}
      </p>
      <p v-else>Unavailable</p>
      <p>Interview 2 Score:</p>
      <p v-if="data[0]?.interview_schedules[1]" class="text-darkGrey">
        {{ data[0]?.interview_schedules[1]?.interview_score?.total }}/80
      </p>
      <p v-else>Unavailable</p> -->
    </section>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    data: {
      type: Object,
    },
    cardType: {
      type: String,
      default: "search",
    },
  },

  created() {
    console.log("data ", this.data)
  },

  methods: {
    convertDate(data) {
      return moment(data).format("DD-MM-YYYY");
    },
  },
};
</script>
