<template>
  <section class="flex justify-between my-5 mx-10">
    <h1 class="font-bold">Vacancy Applicant</h1>
    <div class="flex gap-x-2">
      <router-link :to="{ name: 're-manage-vacancy-create-user' }">
        <span class="material-icons cursor-pointer"> person_add </span>
      </router-link>
      <div v-if="liveStatus">
        <span
          @click="$emit('toggle-online-postings')"
          class="material-icons text-lightGreen cursor-pointer"
        >
          <template v-if="!status">check_circle_outline</template>
          <template v-else>check_circle</template>
        </span>
      </div>
      <div v-else>
        <span
          v-if="!status"
          @click="$emit('toggle-online-postings')"
          class="material-icons text-red cursor-pointer"
        >
          highlight_off
        </span>
        <span
          v-else
          @click="$emit('toggle-online-postings')"
          class="material-icons text-red cursor-pointer"
        >
          cancel
        </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    status: {
      type: Boolean,
      required: true,
    },
    liveStatus: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
