<template>
  <div>
    <form @submit.prevent>
      <section
        id="newVacancyListing"
        class="
          grid grid-cols-3
          gap-1
          justify-items-stretch
          place-items-center
          bg-white
          rounded-xl
          m-10
          my-5
          p-5
          gap-x-2
        "
      >
        <label class="" for="siteName">Site Name:</label>
        <v-select
          v-model="payload.site_name"
          name="siteName"
          class="style-chooser-site-name-active col-span-2"
          :class="
            v$.payload.site_name.$error === true
              ? 'style-chooser-site-name-active-error'
              : 'style-chooser-site-name-active'
          "
          placeholder="Select Platform"
          label="customLabel"
          :options="dropdownData"
        ></v-select>
        <label for="linkToPost" class="flex items-center"
          ><span class="material-icons"> link </span>Link to post:
        </label>
        <input
          class="border rounded-md p-1 bg-lightGrey col-span-2"
          :class="
            v$.payload.link.$error === true ? 'border-red' : 'border-grey'
          "
          type="text"
          placeholder="Link to post"
          v-model.trim="payload.link"
        />
        <label for="postedOn">Posted on:</label>
        <input
          class="border rounded-md p-1 bg-lightGrey col-span-2"
          :class="
            v$.payload.post_date.$error === true ? 'border-red' : 'border-grey'
          "
          type="date"
          v-model="payload.post_date"
        />
        <label for="endsOn">Ends on:</label>
        <input
          class="border rounded-md p-1 bg-lightGrey col-span-2"
          :class="
            v$.payload.end_date.$error === true ? 'border-red' : 'border-grey'
          "
          type="date"
          v-model="payload.end_date"
        />
        <div class="col-start-3 justify-self-end">
          <button
            v-if="!isLoading"
            class="rounded-full text-white bg-teal px-4 py-1 mt-2"
          >
            Submit
          </button>
          <base-button
            class="mt-2"
            @click="submitCreateListing"
            :animateButton="animateButton"
          >
            {{ animateButton ? '' : 'Submit' }}
          </base-button>
        </div>
      </section>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, url } from "@vuelidate/validators";
export default {
  emits: ["create-job-posting"],

  data() {
    return {
      v$: useVuelidate(),
      isLoading: true,
      animateButton: false,
      payload: {
        hiring_request: this.$route.params.manageVacancy,
        site_name: null,
        post_date: null,
        end_date: null,
        link: null,
        dropdownValue: null,
      },
      dropdownData: ["Indeed", "Reed", "Totaljobs", "CWJobs"],
    };
  },
  validations() {
    return {
      payload: {
        hiring_request: { required },
        site_name: { required },
        post_date: { required },
        end_date: { required },
        link: { required, url },
      },
    };
  },
  methods: {
    async submitCreateListing() {
      this.v$.$validate();
      console.log("submited", this.v$);

      if (!this.v$.$error) {
        try {
          this.isLoading = true;
          this.animateButton = true;
          await this.$store.dispatch(
            "REnewHire/createJobPosting",
            this.payload
          );
          this.$emit("create-job-posting");
          this.isLoading = false;
        } catch (error) {
          this.error = error.message || "Something went wrong!";
        }
      } else {
        console.log("sdsd");
        return;
      }
    },
  },
};
</script>

<style>
.style-chooser-site-name-active .vs__dropdown-toggle,
.style-chooser-site-name-active .vs__dropdown-menu {
  border: 1px solid #425563;
  height: 8rem;
  border-radius: 0.5rem;
  background: #e8edee;
  padding-left: 0.2rem;
}
.style-chooser-site-name-active .vs__dropdown-toggle {
  height: 2.2rem;
  width: 100%;
}
.style-chooser-site-name-active .vs__clear,
.style-chooser-site-name-active .vs__open-indicator {
  fill: black;
}
.style-chooser-site-name-active-error .vs__dropdown-toggle,
.style-chooser-site-name-active-error .vs__dropdown-menu {
  border: 1px solid red;
}
</style>
